import { AuthProvider } from 'react-admin'

const authProvider: AuthProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    localStorage.setItem('username', username)
    localStorage.setItem('token', password)

    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('username')
    localStorage.removeItem('token')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
}

export default authProvider
