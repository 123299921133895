import React, { useEffect } from 'react';
import { List, ResourceContextProvider, } from 'react-admin';
import { RowActionBuilder, RowClickEvent, Table } from './Table';
import { getFiltersFromURI } from './helpers';
import { ColumnModel } from '../model/ColumnModel';

export const Resource: React.FC<Props> = ({
  resource, 
  onRowClick,
  disableUrlFilterListener = true, 
  filters,
  rowActionBuilder,
  tableActions, 
  initialFilters = {},
  columnModel,
}) => {
  const [savedFilters, setSavedFilters] = React.useState<{ [index:string]: any}>({});

  useEffect(()=> {
    if (disableUrlFilterListener) return;
    const filters = getFiltersFromURI();
    setSavedFilters(filters);
  }, [window.location.hash]);

  return (
    <ResourceContextProvider value={resource}>
      <List
        disableSyncWithLocation
        filters={filters}
        filterDefaultValues={initialFilters}
        actions={tableActions}>

        <Table
          savedFilters={savedFilters}
          onRowClick={onRowClick}
          rowActionBuilder={rowActionBuilder}
          constantFilters={initialFilters}
          columnModel={columnModel}
        />

      </List>
    </ResourceContextProvider>
  )
}

type Props = {
  resource: string
  disableUrlFilterListener?: boolean
  onRowClick?: (event: RowClickEvent) => void
  filters?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactElement<any, string | React.JSXElementConstructor<any>>[] | undefined
  rowActionBuilder?: RowActionBuilder 
  tableActions?: React.ReactElement
  initialFilters?: { [index:string]: any }
  columnModel: ColumnModel
}
