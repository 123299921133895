import { useEffect, useState } from "react";
import { NumberInput, TextInput } from "react-admin";
import { ChargesCollection } from "../collections/charges-collection/ChargesCollection";
import { DebtCollection } from "../collections/debt-collection/DebtCollection"; 
import { UserBankAccountsCollection } from "../collections/user-bank-accounts-collection/UserBankAccountsCollection";
import { UserInfoCollection } from "../collections/user-info-collection/UserInfoCollection";
import { Resource } from "../common/Resource";

export const Dashboard = () => {
  const [ userId, setUserId ] = useState<string | undefined | null>(undefined);

  useEffect(() => {
    console.log("---->", userId);
  }, [userId]);

  return (
    <section className='dashboard'>
      <h1>Dashboard</h1>

      <DebtCollection onSet={setUserId} />

      { userId !== undefined && userId !== null && 
        <ChargesCollection initialFilters={{ "user_id": { "eq": userId } }} /> 
      }

      { userId !== undefined && userId !== null && 
        <UserBankAccountsCollection initialFilters={{ "user_id": { "eq": userId } }} /> 
      }
      
      { userId !== undefined && userId !== null && 
        <UserInfoCollection initialFilters={{ "user_id": { "eq": userId } }} /> 
      }

    </section>
  );
}
