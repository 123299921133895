import React, { useCallback, useEffect, useState } from "react";
import {
  Datagrid,
  DateField,
  ExportButton,
  List,
  NumberField,
  ResourceContextProvider,
  TextField,
  TopToolbar,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';

export type MicrobiltTablePayload = {
  userId: string
}

export const resource = "admin/generic-views/collection_microbilt_reports";

export const MicrobiltTableDrawer: React.FC<Props> = ({
  payload,
  onClose,
}) => {

  const Actions = () => <TopToolbar>
    <ExportButton />
    <Button label="close" onClick={onClose} />
  </TopToolbar>;

  console.log(payload);

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Historical plaid transactions">

      <ResourceContextProvider value={resource}>
        <List
          disableSyncWithLocation
          actions={<Actions />}
          filterDefaultValues={{user_id: { eq: payload?.userId }}}>

          <Datagrid
            isRowSelectable={() => false}
            rowClick={(_, __, record) => {
              console.log(record);
              return '';
            }}>

            <TextField source="id" />
            <NumberField source="user_id" />
            <DateField source="created_at" />
            <TextField source="bank_account_verify_advantage" />
            <TextField source="ach_and_check_prescreen" />
            <TextField source="id_verify" />
            <TextField source="enhanced_people_search" />
            <TextField source="trace_detail" />
            <TextField source="phone_search" />
            <TextField source="reverse_phone_search" />
            <TextField source="bankruptcy_search" />
            <TextField source="ibv" />

          </Datagrid>

        </List>
      </ResourceContextProvider>
    </BottomDrawer>
  );
}

type Props = {
  payload: MicrobiltTablePayload | undefined
  onClose: () => void
}