import React, { useMemo } from "react";
import {
  ExportButton,
  TopToolbar,
} from "react-admin"
import { Button } from 'react-admin';
import { BottomDrawer } from '../../../common/BottomDrawer';
import { Resource } from "../../../common/Resource";
import { ColumnModel } from "../../../model/ColumnModel";

export const resource = "admin/generic-views/collection_historical_plaid_transactions";

export type HistoricalTablePayload = {
  plaidAccountId: string
}

const tableColumns = [
  { field: "created_at", width: "180px", title: "created at", show: true },
  { field: "plaid_amount", width: "120px", title: "plaid amount", show: true },
  { field: "plaid_authorized_date", width: "200px", title: "plaid authorized date", show: true },
  { field: "plaid_name", width: "120px", title: "plaid name", show: true },
  { field: "plaid_merchant_name", width: "120px", title: "plaid merchant name", show: true },
  { field: "plaid_date", width: "120px", title: "plaid date", show: true },
  { field: "plaid_datetime", width: "120px", title: "plaid datetime", show: true },
  { field: "plaid_authorized_datetime", width: "120px", title: "plaid authorized datetime", show: true },
  { field: "plaid_personal_finance_category_primary", width: "300px", title: "finance category primary", show: true },
  { field: "plaid_personal_finance_category_detailed", width: "300px", title: "finance category detailed", show: true },
]

export const HistoricalPlaidTransactionTableDrawer: React.FC<Props> = ({
  payload,
  onClose,
}) => {
  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  const Actions = () => <TopToolbar>
    <ExportButton />
    <Button label="close" onClick={onClose} />
  </TopToolbar>;

  return (
    <BottomDrawer
      isShow={payload !== undefined}
      onClose={onClose}
      title="Historical plaid transactions">

      {
        payload !== undefined &&
        <Resource
          resource={resource}
          tableActions={<Actions />}
          initialFilters={{ plaid_account_id: { eq: payload.plaidAccountId } }}
          columnModel={columnModel}
        />
      }

    </BottomDrawer>
  );
}

type Props = {
  payload: HistoricalTablePayload | undefined
  onClose: () => void
}