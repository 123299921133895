import { DataProvider, GetListResult } from "react-admin"
import { stringify } from 'query-string'
import { httpClient, headRequest } from "./httpClient"

function _buildQuery(queryMap: { [index: string]: string | number }, filters: Array<string>): string {
  let result = "";

  for (const [key, value] of Object.entries(queryMap)) {
    result += `${key}=${value}&`;
  }

  if (filters.length > 0) {
    if (result.length === 0) {
      result += "&";
    }

    result += filters.join('&');
  }

  const query = result.substring(0, result.length);

  if (query.length > 0) {
    return `?${query}`;
  }

  return '';
}

export const buildDataProvider = (apiUrl: string): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage, } = params.pagination

    const paginationQuery = {
      limit: perPage,
      offset: (page - 1) * perPage,
    }

    const filters: Array<string> = [];

    for (const filterKey of Object.keys(params.filter || {})) {
      const valueObj = params.filter[filterKey];
      if (typeof valueObj === "string") {
        filters.push(`${filterKey}=eq.${valueObj}`);
        continue;
      }

      for (const valueKey of Object.keys(valueObj)) {
        const value = valueObj[valueKey];
        filters.push(`${filterKey}=${valueKey}.${value}`);
      }
    };

    const fullQuery = _buildQuery({ ...paginationQuery }, filters);
    const countQuery = _buildQuery({}, filters);

    const url = `${apiUrl}/${resource}${fullQuery}`;
    const countUrl = `${apiUrl}/${resource}${countQuery}`;

    const valueRequest = httpClient(url);
    const recordCountsRequest = headRequest(countUrl);

    return Promise.all([valueRequest, recordCountsRequest]).then((results) => {
      const [{ json }, { headers }] = results;

      const contentRangeString = headers.get('content-range')?.split('/').shift()?.split('-').pop() as string;

      const contentRange = contentRangeString === "*" ? null : contentRangeString;

      const total = contentRange == null ? 0 : parseInt(contentRange, 10) + 1;

      return {
        data: json,
        total: total,
      }
    });
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/admin/customer/${params.id}/overview`).then(
      ({ json }) => {
        const data = json.result.overview
        data.id = params.id
        return {
          data: data,
        }
      }
    ),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`
    return httpClient(url).then(({ json }) => ({ data: json }))
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination
    const { field, order } = params.sort
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    }
    const url = `${apiUrl}/${resource}?${stringify(query)}`

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: 0,
    }))
  },

  update: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'Post',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    }
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }))
  },
});
