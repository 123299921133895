import * as React from 'react';
import { Menu } from 'react-admin';
import BookIcon from '@mui/icons-material/Book';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import { Link } from 'wouter';

export const AdminMenu = () => (
    <Menu>
        {/* <Menu.DashboardItem /> */}

        <Link to="/">
          one
        </Link>

        <Link to="/two/">
          two
        </Link>
    </Menu>
);