import React from 'react';

import { ActionRequest } from '../network/ActionRequests';
import { AdminRequest } from '../network/AdminRequest';

const actionRequestBuilder = () => {
  const token = localStorage.getItem('token') ?? "";

  if (token === null) {
    throw Error("Build actionRequest failed: token not found.");
  }

  return new ActionRequest(
    new AdminRequest(token),
  );
}

export const Container = {
  actionRequest: actionRequestBuilder(),
};

export type DIContainerType = typeof Container;
export const DIContext = React.createContext<Partial<DIContainerType>>(Container);