
import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { SimpleDialog } from '../../../common/SimpleDialog';
import { DepositoryAccount } from '../../../types/accountsOverview';
import { useNotify, useRefresh } from 'react-admin';
import { useInjection } from '../../../di/useInjection';

export type UpdateBtTransactionDialogPayload = {
  userId: string
  transactionId: number
  transactionDate: Date
  transactionAmount: number
  depositoryAccounts: Array<DepositoryAccount>
  fromAccount?: number
} | null;

export type FromAccountTag = "DepositoryAccount" | "ManualAccount";

export const UpdateBtTransactionDialog: FC<Props> = ({
  payload,
  hideDialog,
}) => {
  const { actionRequest } = useInjection();

  const notify = useNotify();
  const refresh = useRefresh();

  const [transactionDate, setTransactionDate] = useState<string>("");
  const [transactionAmount, setTransactionAmount] = useState<string>("");
  const [fromAccountTag, setFromAccountTag] = useState<FromAccountTag>("ManualAccount");
  const [fromAccountContent, setFromAccountContent] = useState<string>("");
  const [forcedSend, setForcedSend] = useState<boolean>(false);
  const [hideTransaction, setHideTransaction] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // init
  useEffect(() => {
    if (payload !== null) {
      setTransactionDate(payload.transactionDate.toString());
      setTransactionAmount(payload.transactionAmount.toString());
      setForcedSend(false);

      const { fromAccount } = payload;
      setFromAccountTag(payload.fromAccount === undefined ? "ManualAccount" : "DepositoryAccount");
      if (fromAccount !== undefined) {
        setFromAccountContent(fromAccount.toString());
      }
    }
  }, [payload]);

  const onTransactionDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTransactionDate(value);
  }

  const onTransactionAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTransactionAmount(value);
  }

  const handleFromAccountTagChange = useCallback((e: SelectChangeEvent<FromAccountTag>) => {
    setFromAccountTag(e.target.value as FromAccountTag);
  }, [fromAccountTag]);

  const handleFromAccountContentChange = useCallback((e: SelectChangeEvent<string>) => {
    setFromAccountContent(e.target.value);
  }, [fromAccountContent]);

  const handleForcedSendChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setForcedSend(isSelect);
  }, []);

  const handleHideTransactionChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, isSelect: boolean) => {
    setHideTransaction(isSelect);
  }, []);


  const handleSendClick = async () => {
    if (payload === null) return;

    const { userId, transactionId } = payload;

    const fromAccount = fromAccountTag === "DepositoryAccount" ? {
      tag: "DepositoryAccount",
      contents: parseInt(fromAccountContent),
    } : { tag: "ManualAccount" }

    const error = await actionRequest.updatePBTransaction({
      userId,
      transactionId,
      fromAccount,
      scheduledDate: transactionDate.toString(),
      amount: parseFloat(transactionAmount),
      comment: "updated by test admin",
      forcedSend: forcedSend,
      isHiddenInAdminView: hideTransaction
    });

    setError(error);

    if (error === null) {
      hideDialog();
      refresh();
      notify("updated");
    }
  }

  return (
    <SimpleDialog
      isShow={payload !== null}
      onClose={hideDialog}
      title="Update transaction"
      actionTitle='Send'
      onSend={handleSendClick}>

      <TextField
        fullWidth
        type="date"
        label="Transaction date"
        value={transactionDate}
        onChange={onTransactionDateChange}
        variant="outlined"
      />

      <TextField
        fullWidth
        type="number"
        label="Transaction amount"
        value={transactionAmount}
        onChange={onTransactionAmountChange}
        variant="outlined"
      />

      <FormControl fullWidth>
        <InputLabel id="method-select-label">Method</InputLabel>
        <Select
          autoFocus={false}
          onFocus={() => { }}
          labelId="method-select-label"
          id="demo-simple-select"
          value={fromAccountTag}
          label="Method"
          onChange={handleFromAccountTagChange}>
          <MenuItem value={"DepositoryAccount"}>DepositoryAccount</MenuItem>
          <MenuItem value={"ManualAccount"}>ManualPay</MenuItem>
        </Select>
      </FormControl>

      {
        fromAccountTag === "DepositoryAccount" &&
        <FormControl fullWidth>
          <InputLabel id="account-id-select-label">From account id</InputLabel>
          <Select
            autoFocus={false}
            onFocus={() => { }}
            labelId="account-id-select-label"
            id="demo-simple-select"
            value={fromAccountContent}
            label="Account id"
            onChange={handleFromAccountContentChange}>
            {payload?.depositoryAccounts.map(({ depositoryId, bankName, mask }) =>
              <MenuItem key={depositoryId} value={depositoryId.toString()}>{bankName} : *{mask} - {depositoryId}</MenuItem>
            )}
          </Select>
        </FormControl>
      }

      <FormControlLabel control={<Checkbox onChange={handleForcedSendChange} />} label="forced send" />
      <FormControlLabel control={<Checkbox onChange={handleHideTransactionChange} />} label="hide transaction" />

      {error !== null &&
        <Alert color={"error"} severity="error">{error}</Alert>}

    </SimpleDialog>
  );
}

type Props = {
  payload: UpdateBtTransactionDialogPayload;
  hideDialog: () => void;
}