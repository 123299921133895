import { Admin, Resource, combineDataProviders, DataProvider, ListGuesser, DashboardComponent } from 'react-admin'
import { CreditLineList } from './components/CreditLineList'
import { CreditLineShow } from './components/CreditLineShow'
import UserIcon from '@material-ui/icons/Group'
import authProvider from './providers/authProvider'
import { dataProvider as creditLinesDataProvider } from './providers/dataProvider'
import { Constants } from './constants'
import { buildDataProvider } from './providers/buildDataProvider'
import { resource } from './collections/debt-collection/DebtCollection'
import { AdminLayout } from './layout/AdminLayout'
import { config } from './config'

const dataProvider = combineDataProviders(
  (resource: string): DataProvider<string> => {
    switch (resource) {
      case Constants.CREDIT_LINES:
        return creditLinesDataProvider;
      default:
        return buildDataProvider(config.apiUrl);
    }
  }
);

const Dashboard = () => {
  return (
    <div>dashboard 2</div>
  )
}

const App2: React.FC = () => {
  return (
    <div>
      <Admin
        title="My Custom Admin"
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        basename='/one'
        layout={AdminLayout}>
        <Resource
          name={Constants.CREDIT_LINES}
          options={{ label: 'Credit lines' }}
          list={CreditLineList}
          icon={UserIcon}
          show={CreditLineShow}
        />
        {/* <Resource
          name={Constants.CREDIT_LINES}
          options={{ label: 'Credit lines' }}
          list={CreditLineList}
          icon={UserIcon}
          show={CreditLineShow}
        /> */}
      </Admin>
    </div>
  )
}

export default App2;
