import React from "react";
import {
  Drawer,
} from '@mui/material';

export const BottomDrawer: React.FC<Props> = ({
  isShow,
  onClose,
  children,
  title,
}) => {

  return (
    <Drawer
      open={isShow}
      anchor={"bottom"}
      onClose={onClose}
      title={title}>
        {children}
    </Drawer>
  );
}

type Props = {
  isShow: boolean
  onClose: () => void
  title: string
}