import './lib/intercom.js';

import React, { createContext } from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import App2 from './App2'
import reportWebVitals from './reportWebVitals'
import { Route } from "wouter";

ReactDOM.render(
  <React.StrictMode>
    <Route path="/two/:rest*" component={App2} />
    <Route path="/:rest*" component={App} />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
window.Intercom('shutdown');
