import React, { ReactElement, useEffect } from 'react';
import { useListContext } from 'react-admin';
import {
  Grid,
  GridColumn,
  GridColumnMenuSort,
  GridColumnMenuProps,
  GridDataStateChangeEvent
} from '../lib/kendo-react-grid';
import {
  State as GridState
} from "../lib/kendo-data-query";
import {
  SortDescriptor
} from '../lib/kendo-data-query/dist/npm/sort-descriptor';
import { ColumnModel } from '../model/ColumnModel';
import { observer } from "mobx-react";

export const Table: React.FC<Props> = observer((props) => {
  const {
    savedFilters,
    onRowClick,
    rowActionBuilder,
    constantFilters = {},
    columnModel,
  } = props;
  const listData = useListContext(props);

  const { data } = listData;

  const [gridState, setGridState] = React.useState<GridState>();

  useEffect(() => {
    const filters = { ...savedFilters, ...constantFilters };
    listData.setFilters(filters, savedFilters);
  }, [savedFilters, constantFilters]);

  const getNewSort = (currentSort: SortDescriptor, allSorts: SortDescriptor[]): SortDescriptor[] => {
    const existsIndex = (gridState?.sort ?? []).map((s) => s.field).indexOf(currentSort.field);
    if (existsIndex >= 0) {
      const newSort = [...allSorts];
      newSort[existsIndex] = currentSort;
      return newSort;
    }

    return [...allSorts, currentSort];
  }

  const handleDataStateChange = ({ dataState }: GridDataStateChangeEvent) => {
    const { sort } = dataState;

    if (sort === undefined || sort?.length === 0) {
      setGridState({ sort });
      return;
    }

    const [currentSort] = sort;

    const newSort = getNewSort(currentSort, gridState?.sort ?? []);

    setGridState({
      ...gridState,
      sort: newSort,
    })

    const order: { [index: string]: string } = {};

    for (const item of newSort) {
      order[item.field] = item.dir!;
    }

    const newFilters = {
      ...listData.filterValues ?? {},
      order,
      ...constantFilters,
    }

    listData.setFilters(newFilters, newFilters);
  }

  return (
    <>

      <Grid
        data={data}
        {...gridState}
        onColumnReorder={({ columns }) => columnModel.reorder(columns)}
        onColumnResize={(event) => {
          if (!event.end) return;
          columnModel.resize(event);
        }}
        onDataStateChange={handleDataStateChange}
        sortable
        resizable
        onRowClick={onRowClick}
        cellRender={
          (defaultRender, cellProps) => {
            if (cellProps.field === "actions" && rowActionBuilder !== undefined) {
              const action = rowActionBuilder(cellProps.dataItem);
              return (
                <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                  {action}
                </td>
              )
            }

            return defaultRender;
          }
        }
        reorderable>
        {
          columnModel.columns.map((column) =>
            column.show && (<GridColumn
              key={column.field}
              orderIndex={column.orderIndex}
              field={column.field}
              title={column.title}
              width={column.width}
              columnMenu={
                props => <CustomColumnMenu {...props} />
              }
            />)
          )
        }
      </Grid>

    </>
  )
});

const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <div>
      <GridColumnMenuSort {...props} />
    </div>
  )
}

export type RowClickEvent = {
  dataItem: any,
  nativeEvent: any,
}

export type RowActionBuilder = (record: { [index: string]: any }) => ReactElement

type Props = {
  savedFilters: { [index: string]: any }
  onRowClick?: (event: RowClickEvent) => void
  rowActionBuilder?: RowActionBuilder
  constantFilters?: { [index: string]: any }
  columnModel: ColumnModel
}
