import React  from 'react';
import { ExportButton, FilterButton, TopToolbar } from 'react-admin';
import { ColumnModel } from '../model/ColumnModel';
import { ToggleColumnButton } from './ToggleColumnButton';

export const ColumnActions: React.FC<Props> = (props) => {
  return (
    <TopToolbar>
      <ToggleColumnButton {...props} />
      <FilterButton {...props} />
      <ExportButton />
    </TopToolbar>
  );
}

type Props = {
  columnModel: ColumnModel
}
