import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UpdateIcon from '@mui/icons-material/Update';
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNotify, useRefresh } from 'react-admin';
import { reduce } from 'lodash';
import { AccountsOverview, DepositoryAccount, DepositoryAccountWrapper } from '../../../types/accountsOverview';
import { UpdateBtTransactionDialog, UpdateBtTransactionDialogPayload, } from './UpdateBtTransactionDialog';
import { CreateBtTransactionDialog, CreateBtTransactionDialogPayload, } from './CreateBtTransactionDialog';
import { Divider } from '@mui/material';
import { UpdateBCDDDialog, UpdateBCDDDialogPayload } from './UpdateBСDDDialog';
import { useInjection } from '../../../di/useInjection';
import { selectDepositoryAccounts } from '../ChargesCollection';

const EDITABLE_STATUSES = [
  "Scheduled",
  "Pending",
]

const _isTransactionEditable = (transactionStatus: string): boolean => {
  return EDITABLE_STATUSES.includes(transactionStatus);
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export const RowActions: React.FC<Props> = ({
  record,
  accountsOverview,
}) => {
  const { actionRequest } = useInjection();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userId, setUserId] = React.useState<string | null>(null);
  const open = Boolean(anchorEl);

  const refresh = useRefresh();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const userId = record.user_id;
    setUserId(userId);
    setAnchorEl(event.currentTarget);
  };

  const [
    updateBtTransactionPayload,
    setUpdateBtTransactionPayload,
  ] = React.useState<UpdateBtTransactionDialogPayload>(null);

  const [
    updateBCDueDateDialogPayload,
    setUpdateBCDueDateDialogPayload,
  ] = React.useState<UpdateBCDDDialogPayload>(null);

  const notify = useNotify();

  const handleClose = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(null);
  };

  const hideMenu = () => {
    setAnchorEl(null);
  }

  const handleChangeBtTransaction = () => {
    const userId = record.user_id;
    const transactionDate = record.scheduled_date as Date;
    const transactionAmount = record.amount;
    const fromAccount = record.from_account_id;
    const transactionId = record.scheduled_pb_txn_id;

    console.log(userId, transactionId);

    if (accountsOverview === undefined) {
      notify("accounts overview not loaded");
      return;
    }

    setUpdateBtTransactionPayload({
      transactionId,
      userId,
      transactionDate,
      transactionAmount,
      fromAccount,
      depositoryAccounts: selectDepositoryAccounts(accountsOverview),
    });

    hideMenu();
  }

  const handleCancelBtTransaction = async () => {
    const userId = record.user_id;
    const transactionId = record.id;

    if (!window.confirm(`Cancel transaction ${transactionId}`)) {
      hideMenu();
      return;
    }

    const error = await actionRequest.cancelPBTransaction({
      userId: parseInt(userId),
      transactionId: parseInt(transactionId),
      comment: "Canceled by admin",
    });

    if (error === null) {
      notify(`${transactionId} canceled`);
      refresh();
      hideMenu();
    } else {
      hideMenu();
      notify(error, { type: "error" });
    }
  }

  const handleUpdateBCDueDate = () => {
    const userId = record.user_id;
    const billingCycleId = record.single_bill_cycle_id;
    const dueDate = record.payment_due_date;

    if (userId === null || userId === undefined) {
      notify("userId is unknown");
      return;
    }
    
    if (billingCycleId === null || billingCycleId === undefined) {
      notify("billingCycleId is unknown");
      return;
    }

    setUpdateBCDueDateDialogPayload({
      userId,
      billingCycleId,
      dueDate,
    });

    hideMenu();
  }

  return (
    <div>
      <Button
        disabled={!_isTransactionEditable(record.current_status)}
        size='small'
        color='inherit'
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}>
        Actions
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>

        <div>
          <MenuItem onClick={handleChangeBtTransaction} disableRipple>
            <UpdateIcon />
            Change transaction
          </MenuItem>

          <MenuItem onClick={handleCancelBtTransaction} disableRipple>
            <CancelIcon />
            Cancel transaction
          </MenuItem>

          <Divider />

          <MenuItem onClick={handleUpdateBCDueDate} disableRipple>
            <UpdateIcon />
            Update BC due date
          </MenuItem>
        </div>

      </StyledMenu>

      <UpdateBtTransactionDialog
        payload={updateBtTransactionPayload}
        hideDialog={() => setUpdateBtTransactionPayload(null)} />

      <UpdateBCDDDialog
        payload={updateBCDueDateDialogPayload}
        hideDialog={() => setUpdateBCDueDateDialogPayload(null)} />

    </div>
  );
}

type Props = {
  record: any;
  accountsOverview?: AccountsOverview;
}