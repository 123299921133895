import { Box } from "@mui/material";
import { reduce } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Resource } from "../../common/Resource";
import { useInjection } from "../../di/useInjection";
import { AccountsOverview, DepositoryAccount, DepositoryAccountWrapper, } from "../../types/accountsOverview";
import { BillingCycle } from "../../types/billingCycles";
import { RowActions } from "./widget/RowActios";
import { ChargesActions } from "./widget/ChargesActions";
import { ColumnModel } from "../../model/ColumnModel";

export const resource = "admin/generic-views/collection_scheduled_pb_transactions";

export const selectDepositoryAccounts = (accountsOverview: AccountsOverview): DepositoryAccount[] => {
  return reduce(
    accountsOverview.depositoryAccount,
    (acc: Array<DepositoryAccount>, item: DepositoryAccountWrapper) => [
      ...acc,
      ...item.accounts,
    ],
    [],
  );
}

const tableColumns = [
  { field: "user_id", width: "100px", title: "user id", show: true },
  { field: "scheduled_date", width: "150px", title: "scheduled date", show: true },
  { field: "amount", width: "150px", title: "amount", show: true },
  { field: "current_status", width: "200px", title: "current status", show: true },
  { field: "payment_due_date", width: "150px", title: "payment due date", show: true },
  { field: "payment_type", width: "150px", title: "payment type", show: true },
  { field: "from_account_type", width: "150px", title: "from account type", show: true },
  { field: "from_account_name", width: "150px", title: "from account name", show: true },
  { field: "account_status", width: "150px", title: "account status", show: true },
  { field: "available_balance", width: "150px", title: "available_balance", show: true },

  { field: "actions", width: "120px", title: "actions", show: true },
]

export const ChargesCollection: React.FC<Props> = ({ initialFilters }) => {
  const [accountsOverview, setAccountsOverview] = useState<AccountsOverview | undefined>(undefined)
  const [billingCycles, setBillingCycles] = useState<BillingCycle[] | undefined>(undefined);

  const { actionRequest } = useInjection();

  useEffect(() => {
    setAccountsOverview(undefined);

    if (initialFilters === undefined) {
      return;
    }

    const userId = initialFilters.user_id.eq;

    actionRequest.fetchAccountsOverview(userId).then((response) => {
      setAccountsOverview(response);
    });

    actionRequest.fetchBillingCycle(userId).then((response) => {
      setBillingCycles(response);
    });

  }, [initialFilters.user_id.eq]);

  const rowActionBuilder = (record: { [index: string]: any }) => 
    <RowActions accountsOverview={accountsOverview} record={record} />

  const chargesActions = <ChargesActions
    accountsOverview={accountsOverview}
    billingCycles={billingCycles}
    initialFilters={initialFilters}
  />

  const columnModel = useMemo(() => {
    console.log(resource, 'call just once');
    return new ColumnModel(resource, tableColumns);
  }, [])

  return <Box sx={{ position: 'relative' }}>

    <Box sx={{
      position: "absolute",
      background: "#2a41b0",
      color: "#fff",
      padding: "0 20px",
      borderRadius: "10px 10px 0 0",
      top: "30px",
    }}>
      <h4>
        {`Charges: ${initialFilters.user_id.eq}`}
      </h4>
    </Box>

    <Resource
      resource={resource}
      initialFilters={initialFilters}
      rowActionBuilder={rowActionBuilder}
      tableActions={chargesActions}
      columnModel={columnModel}
    />

  </Box>
}

type Props = {
  initialFilters: { "user_id": { "eq": string } }
}