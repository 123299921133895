const defaults = {
  apiUrl: String(process.env.REACT_APP_HOST),
}

const localStorageConfigKey = 'APP_CONFIG'

function getConfig(): typeof defaults {
  try {
    const localStorageConfig = localStorage.getItem(localStorageConfigKey)
    if (localStorageConfig === null) {
      localStorage.setItem(localStorageConfigKey, JSON.stringify(defaults))
    }

    return {
      ...defaults,
      ...JSON.parse(localStorageConfig || '{}'),
    }
  } catch {
    return defaults
  }
}

export const config = getConfig()
