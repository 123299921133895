import { config } from "../config";
import { AccountsOverview } from "../types/accountsOverview";

export type PostError = string | null;

// interface IAdminRequest {
// <T>(args: { userId: string, path: string }): Promise<T>
// (args: { userId: string, path: string, payload: BodyInit}): Promise<PostError>
// (path: string, payload: BodyInit): Promise<PostError>
// }

const NOT_SUCCESS_ONE_TIME_PAYMENT_RESULT = [
  "NoUnpaidOrActiveBillingCycle",
  "UnsupportedAccountHasBeenChosen",
  "SpecifiedAmountIsTooBig",
  "SpecifiedAmountIsTooSmall",
]

export class AdminRequest {
  private apiUrl: string;

  constructor(private apiToken: string) {
    this.apiUrl = config.apiUrl
  };

  async getView<T>(path: string): Promise<T> {
    const url = `${this.apiUrl}${path}`;

    const response = await fetch(url, {
      headers: this._buildHeaders(),
    });

    if (!response.ok) {
      throw Error(`Error fetch ${url}`);
    }

    return await response.json();
  } 

  async getAsUser<T>({ userId, path }: { userId: string, path: string }): Promise<T> {
    const url = `${this.apiUrl}/admin/as-user/${userId}/${path}`;

    const response = await fetch(url, {
      headers: this._buildHeaders(),
    });

    if (!response.ok) {
      throw Error(`Error fetch ${url}`);
    }

    const json = await response.json();

    if (!json.success) {
      throw Error(`Error response ${url}: ${json.error}`);
    }

    return json.result;
  }

  async postAsUser({
    userId,
    path,
    payload
  }: {
    userId: string,
    path: string,
    payload: { [index: string]: any }
  }): Promise<PostError> {
    const url = `${this.apiUrl}/admin/as-user/${userId}/${path}`;

    const response = await fetch(url, {
      method: 'post',
      headers: this._buildHeaders(),
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw Error(`Error fetch ${url}`);
    }

    const json = await response.json();

    if (!json.success) {
      throw Error(`Error response ${url}: ${json.error}`);
    }


    if (NOT_SUCCESS_ONE_TIME_PAYMENT_RESULT.includes(json.result)) {
      return json.result;
    }

    return null;
  }

  async post(
    path: string,
    payload: BodyInit,
  ): Promise<PostError> {
    const url = `${this.apiUrl}${path}`;

    const response = await fetch(url, {
      method: 'post',
      body: payload,
      headers: this._buildHeaders(),
    });

    if (!response.ok) {
      throw Error(`Error post ${url}`);
    }

    const json = await response.json();

    if (!json.success) {
      return json.error.message;
    } else {
      return null;
    }
  }

  _buildHeaders(): HeadersInit {
    return {
      Authorization: `Bearer ${this.apiToken}`,
      "Content-Type": "application/json"
    };
  }
}
