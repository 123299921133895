import { Admin, Resource, combineDataProviders, DataProvider, ListGuesser, ShowGuesser } from 'react-admin'
import { CreditLineList } from './components/CreditLineList'
import { CreditLineShow } from './components/CreditLineShow'
import UserIcon from '@material-ui/icons/Group'
import authProvider from './providers/authProvider'
import { dataProvider as creditLinesDataProvider } from './providers/dataProvider'
import { Constants } from './constants'
import { buildDataProvider } from './providers/buildDataProvider'
import { Dashboard } from './dashboard/Dashboard'
import { resource as debtResource } from './collections/debt-collection/DebtCollection'
import { resource as chargesResource } from './collections/charges-collection/ChargesCollection';
import { resource as bankAccountsResource } from './collections/user-bank-accounts-collection/UserBankAccountsCollection';
import { resource as microbiltResource } from './collections/user-bank-accounts-collection/widget/HistoricalPlaidTransactionTableDrawer';
import { resource as userInfoResource } from './collections/user-info-collection/UserInfoCollection';
import { AdminLayout } from './layout/AdminLayout'
import { config } from './config'

const dataProvider = combineDataProviders(
  (resource: string): DataProvider<string> => {
    switch (resource) {
      case Constants.CREDIT_LINES:
        return creditLinesDataProvider;
      default:
        return buildDataProvider(config.apiUrl);
    }
  }
);

const App = () => {
  return (
    <div>
      <Admin
        title="My Custom Admin"
        authProvider={authProvider}
        dataProvider={dataProvider}
        dashboard={Dashboard}
        basename='/'
        // layout={AdminLayout}
        >
        <Resource
          name={Constants.CREDIT_LINES}
          options={{ label: 'Credit lines' }}
          list={CreditLineList}
          icon={UserIcon}
          show={CreditLineShow}
        />
        {/* <Resource
          name={"admin/generic-views/debt_collection"}
          options={{ label: 'debt' }}
          list={ListGuesser}
          icon={UserIcon}
        /> */}
        {/* <Resource
          name={bankAccountsResource}
          options={{ label: 'bank accounts resource' }}
          list={ListGuesser}
          icon={UserIcon}
        /> */}
        {/* <Resource
          name={microbiltResource}
          options={{ label: 'microbilt resource' }}
          list={ListGuesser}
          icon={UserIcon}
        /> */}
      </Admin>
    </div>
  )
}

export default App
